import React, { Component } from 'react';

import { graphql } from 'gatsby';
import InfiniteScroll from 'react-infinite-scroller';
import Layout from '../layouts/layout';

import { Banner, Reference, Icon } from '../components';

import referencie from '../assets/img/referencie-2.jpg';
import { object } from 'prop-types';

class ReferenciePage extends Component {
  static propTypes = {
    data: object,
  };

  state = {
    items: this.props.data.allWpReferencia.edges,
    cutOn: 4,
  };

  constructor(props) {
    console.log(props.data);
    super(props);
    this.loadMoreData = this.loadMoreData.bind(this);
  }

  loadMoreData() {
    setTimeout(() => {
      this.setState({
        cutOn: this.state.cutOn + 4,
      });
    }, 1200);
  }

  render() {
    const { allWpReferencia } = this.props.data;

    const hasMore = this.state.cutOn - 1 < allWpReferencia.edges.length;

    return (
      <Layout>
        <Banner imgSrc={referencie} isSmall strength={0}>
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <h1
                  className="h1 banner__heading"
                  data-aos="fade-right"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1200"
                >
                  REFERENCIE
                </h1>
              </div>
            </div>
          </div>
        </Banner>
        <section className="box">
          <div className="container">
            <InfiniteScroll
              pageStart={0}
              hasMore={hasMore}
              loader={
                <div className="row justify-content-center">
                  <Icon name="spinner" className="text-color-primary" />
                </div>
              }
              loadMore={this.loadMoreData}
            >
              <div className="row">
                {allWpReferencia.edges
                  .slice(0, this.state.cutOn)
                  .map((item, itemIndex) => (
                    <div
                      key={itemIndex}
                      className="col-md-6"
                      data-aos="fade-up"
                    >
                      <Reference data={item.node} />
                    </div>
                  ))}
              </div>
            </InfiniteScroll>
          </div>
        </section>
      </Layout>
    );
  }
}

export default ReferenciePage;

export function Head() {
  return (
    <>
      <title>
        Elektroinštalácie - referencie, recenzie, odporúčania | ICS Group,
        s.r.o.
      </title>
      <meta name="description" content="Projekty, ktoré sme realizovali." />
      <meta
        name="keywords"
        content="automatizácie, zabezpečovacie systémy, inteligentná domácnosť"
      />
    </>
  );
}

export const query = graphql`
  query Referencie {
    allWpReferencia {
      edges {
        node {
          id
          title
          referencie {
            description
            location
            ikonky
            gallery {
              id
              altText
              description
              caption
              sourceUrl
              mediaDetails {
                width
                height
                file
              }
            }
          }
        }
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;
